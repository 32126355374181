import React from "react";
import styled from "styled-components";

type TPartner = {
  name: string,
  link: string
}

type Props = {
  title: string,
  partners: TPartner[]
}

const ContentPartner = ({ title, partners } : Props) => {

  return (
    <ContentPartnerWrap>
      <div className="inner">

        {title && <div className="col">
          <h2 className="title" dangerouslySetInnerHTML={{__html: title }} ></h2>
        </div> }

        {partners && <div className="col">
          <div className="content">
            <ul>
              {partners.map((partner: TPartner, key:number) => (
                <li key={key}>
                  {partner.link && (<a target="_blank" href={partner.link} rel="noreferrer">{partner.name ? partner.name : partner.link}</a>)}
                  {!partner.link && (partner.name && partner.name)}
                </li>
              ))}
            </ul>
          </div>
        </div>}

      </div>
    </ContentPartnerWrap>
  )
}

export default ContentPartner;

const ContentPartnerWrap = styled.div`
  .title {
    font-size: 50px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 30px 0;
    height: auto;
    width: 100%;
    letter-spacing: 0.1em;

    span {
      display: block;
      height: auto;
      width: 100%;
    }
  }

  .col {
    width: 100%;
  }

  .content {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    height: auto;
    width: 100%;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  @media screen and (min-width: ${({theme}) => theme.breakPoints.desktop}) {
    width: 90%;
    margin: 0 auto;
    
    .inner {
      display: flex;
      width: 100%;
      box-sizing: border-box;
    }
    
    .col {
      box-sizing: border-box;
      overflow: hidden;

      &:first-child {
        width: 60%;
      }

      &:last-child {
        width: 40%;
        padding-left: 10%;
        padding-top: 160px;
        padding-top: 11.11vw;
      }
    }

    .title {
      font-size: 100px;
      font-size: 6.94vw;
      line-height: 1.2;
      margin: 0;
    }

    .content {
      font-size: 1.25vw;
    }
  }
`
